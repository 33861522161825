import React, { Component } from 'react';
import { Modal, Tabs, Tab, Button } from 'react-bootstrap'
import * as typeformEmbed from '@typeform/embed'



export default class Experiences extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      experiencia: null,
      // vatom: {
      //   properties: {
      //     experiences: [1, 10]
      //   }
      // }
    }

    this.handleNotCompleteClick = this.handleNotCompleteClick.bind(this)
  }

  componentWillMount() {
    fetch('https://api.socioinfonavit.com/get_experiencias').then(result => {
      return result.json()
    }).then(res => {
      this.setState({ experiencias: res.experiencias })
    })
    var vatom = new window.VatomicFace.LocalVatom()
    vatom.initVatom().then(() => {
      if (!vatom.properties.owner_viewed) {
        const payload = {
          actionName: "Viewed",
          actionData: {
            "this.id": vatom.identifier
          }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
      }
      this.setState({ vatom: vatom })
    })
  }

  handleNotCompleteClick(experiencia) {
    experiencia.checked = false
    this.setState({ experiencia: experiencia })
  }

  handleCompleteClick(experiencia) {
    experiencia.checked = true
    this.setState({ experiencia: experiencia })
  }

  handleModalClose() {
    this.setState({ showModal: false })
  }

  onBack() {
    this.setState({ experiencia: null })
  }

  handleExperienceAction() {
    const url = 'https://vvb.typeform.com/to/' + this.state.experiencia.survey_id + '?vatom_id=' + this.state.vatom.identifier
    const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this) })
    this.setState({ popup: popup });
    popup.open()
  }

  handleSurveyDone() {
    const new_experiences = this.state.vatom.properties.experiences
    new_experiences.push(this.state.experiencia.id)
    const payload = {
      actionName: "Update",
      actionData: {
        "this.id": this.state.vatom.identifier,
        properties: {
          experiences: new_experiences
        }
      }
    }

    window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
      setTimeout(() => {
        this.state.popup.close()
        this.setState({ experiencia: null, popup: null, showModal: true });
        var vatom = this.state.vatom
        vatom.properties.experiences = new_experiences
        this.setState({ vatom: vatom })
      }, 2000)
    }).catch(err => {
      console.log(err)
    })
  }

  render() {
    var no_completadas = []
    var completadas = []
    if (this.state.experiencias && this.state.vatom) {
      no_completadas = this.state.experiencias.filter(exp => !this.state.vatom.properties.experiences.includes(exp.id))
      completadas = this.state.experiencias.filter(exp => this.state.vatom.properties.experiences.includes(exp.id))
    }
    return (
      <div className="App">
        {!this.state.experiencia && this.state.vatom ?
          <div style={{ maxWidth: "800px", display: "block", margin: "0 auto" }}>
            <div style={{ width: "100vw", height: "30vh", backgroundImage: `url(${require("../../img/header.jpg")}`, backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center' }}> </div>

            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Por completar" style={{ width: "100%", height: "63vh", overflowY: 'scroll' }} >
                {no_completadas.length > 0 ?
                  no_completadas.map((experiencia) => {
                    return (
                      <div key={'no_completada' + experiencia.id} onClick={() => { this.handleNotCompleteClick(experiencia) }} style={{ minHeight: "50px", textAlign: "left", cursor: 'pointer' }}>
                        <div style={{ width: "100%", display: "inline-block", padding: "15px", position: "relative", borderBottom: "1px solid #ccc" }}>
                          <h4 style={{ fontFamily: "Roboto Condensed", maxWidth: "80%" }}>{experiencia.name}</h4>
                          <p style={{ color: "#979797", maxWidth: "80%" }}>{experiencia.description}</p>
                          <img src={require("../../img/chevron.svg")} style={{ height: "5vh", position: 'absolute', right: 15, top: 0, bottom: 0, margin: "auto", display: "block" }} />
                        </div>

                      </div>
                    )
                  })
                  : <h3>Ya completaste todo</h3>}
              </Tab>
              <Tab eventKey={2} title="Completadas" style={{ width: "100%", height: "63vh", overflowY: 'scroll' }}>
                {completadas.length > 0 ?
                  completadas.map((experiencia) => {

                    return (
                      <div key={'no_completada' + experiencia.id} onClick={() => { this.handleCompleteClick(experiencia) }} style={{ minHeight: "50px", textAlign: "left", cursor: 'pointer' }}>
                        <div style={{ width: "100%", display: "inline-block", padding: "15px", position: "relative", borderBottom: "1px solid #ccc" }}>
                          <h4 style={{ fontFamily: "Roboto Condensed", maxWidth: "80%" }}>{experiencia.name}</h4>
                          <p style={{ color: "#979797", maxWidth: "80%" }}>{experiencia.description}</p>
                          <img src={require("../../img/check.svg")} style={{ height: "5vh", position: 'absolute', right: 15, top: 0, bottom: 0, margin: "auto", display: "block" }} />
                        </div>

                      </div>
                    )
                  })
                  : <h3>No tienes nada completado</h3>}
              </Tab>
            </Tabs>
          </div>
          :
          this.state.vatom ?
            <div style={{ maxWidth: "800px", display: "block", margin: "0 auto" }}>
              {this.state.experiencia.checked ?
                <div style={{ height: "100vh" }}>
                  <img onClick={this.onBack.bind(this)} src={require("../../img/chevron_w.png")} style={{ position: 'absolute', top: "15px", left: "15px", width: "30px", transform: "rotate(180deg)", cursor: "pointer" }} />
                  <div style={{ backgroundImage: `linear-gradient(to right bottom, rgb(226, 25, 54, 0.6), rgba(0, 0, 0, 0.6)), url(${this.state.experiencia.image_url})`, height: "40vh", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center top' }}>
                    <h1 style={{ fontFamily: "Roboto Condensed", textAlign: "center", color: "white", margin: "0", paddingTop: "17vh" }}>¡COMPLETADO!</h1>
                  </div>
                  <div style={{ width: "85%", display: "block", margin: "0 auto" }}>
                    <img src={this.state.experiencia.brand} style={{ width: "200px", display: "block", margin: "10% auto" }} />
                    <br></br>
                    <h3 style={{ fontFamily: "Roboto Condensed", textAlign: "center" }}>{this.state.experiencia.name}</h3>
                    <p style={{ color: "#979797", textAlign: "center" }}>{this.state.experiencia.description}</p>
                  </div>
                </div> :
                <div style={{ height: "100vh" }}>
                  <img onClick={this.onBack.bind(this)} src={require("../../img/chevron.png")} style={{ position: 'absolute', top: "15px", left: "15px", width: "30px", transform: "rotate(180deg)", cursor: "pointer" }} />
                  <div style={{ backgroundImage: `url(${this.state.experiencia.image_url})`, height: "40vh", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center top' }}>
                  </div>
                  <div style={{ width: "85%", display: "block", margin: "0 auto" }}>

                    <h3 style={{ fontFamily: "Roboto Condensed", textAlign: "center", marginTop: "15%" }}>{this.state.experiencia.name}</h3>
                    <p style={{ color: "#979797", textAlign: "center" }}>{this.state.experiencia.description}</p><br></br><br></br>
                    <a onClick={this.handleExperienceAction.bind(this)} style={{ padding: "15px", borderRadius: "50px", background: "#bc2e47", width: "50%", textAlign: "center", color: "white", fontWeight: "bolder" }}>Completar Experiencia</a>
                  </div>
                </div>
              }
            </div>
            : null
        }
        <Modal show={this.state.showModal} onHide={this.handleModalClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={require("../../img/tnx.png")} style={{ width: "100%", margin: "0 auto", display: "block", padding: "30px 0" }} />
            <h3 style={{ textAlign: "center" }}><b>¡Una experiencia más a la lista!</b><br></br>
              Sigue completando las experiencias que Socio Infonavit trae para ti.</h3>
            <br></br>

            <Button bsStyle="primary" bsSize="large" onClick={this.handleModalClose.bind(this)} style={{ margin: "0 auto", display: "block" }}>
              Ok
              </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
