import React, { Component } from 'react';
// import ScratchCard from 'react-scratchcard';
import ScratchCard from './ScratchCard';


export default class Scratch extends Component {
    constructor() {
        super()
        this.state = {
            random: Math.random(),
            showHand: false,
            playTimes: 1
        }
        this.imageLoaded = this.imageLoaded.bind(this);
    }

    componentDidMount() {
        var vatom = new window.VatomicFace.LocalVatom()
        vatom.initVatom().then(() => {
            if (!vatom.properties.owner_viewed) {
                const payload = {
                    actionName: "Viewed",
                    actionData: {
                        "this.id": vatom.identifier
                    }
                }
                window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            }
            this.setState({ vatom: vatom})
            
        })
    }

    handleRedeem() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": this.state.vatom.properties.merchant_id
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.top.location = 'https://app.socioinfonavit.com/';
            }
        });
    }

    handePlayAgain(){
        this.setState({ done: false, random: Math.random(), playTimes:this.state.playTimes + 1}) 
        // this.setState({ done: false, random: Math.random()}) 
        // window.location.reload();
    }

    imageLoaded(){
        this.setState({showHand:true})
        setTimeout(() => {
            this.setState({ showHand: false })
        }, 1000)
    }

    render() {
        
        const settings = {
            width: window.innerWidth*.8,
            height: window.innerWidth * .8,
            image: this.state.vatom ? this.state.vatom.resources.ScratchImage : "",
            finishPercent: 60,
            imageLoaded:()=>{this.imageLoaded()},
            onComplete:()=>{this.setState({done:true})},
        };
        return (
            this.state.vatom?
            <div style={{background:'#313131', height:"100vh",position:"relative"}}>
                <ScratchCard key={this.state.playTimes} {...settings}>
                    {
                            this.state.random <= this.state.vatom.properties.winning_odds ?
                            <img style={{ width: settings.width, height: settings.height }} src={this.state.vatom.resources.WinImage} />
                            :
                            <img style={{ width: settings.width, height: settings.height }} src={this.state.vatom.resources.LostImage} />
                    }
                </ScratchCard>
                
                {this.state.done && this.state.random <= this.state.vatom.properties.winning_odds ?
                    // Boton remidmir
                    <a href="#" onClick={this.handleRedeem.bind(this)} style={{position:"absolute", bottom:"10%", left:0, right:0, width:'90%', padding:'15px', backgroundColor: this.state.vatom.properties.redeem_button_background_color, display: 'block', margin: 'auto', color: this.state.vatom.properties.redeem_button_text_color, borderRadius: '50px', textAlign: 'center', fontWeight: 'bold' }}>{this.state.vatom.properties.redeem_text}</a> : this.state.done ? 
                    // Boton Jugar de Nuez
                            <a onClick={this.handePlayAgain.bind(this)} style={{ position: "absolute", bottom: "10%", left: 0, right: 0, width: '80%', padding: '15px', backgroundColor: this.state.vatom.properties.play_again_button_background_color, display: 'block', margin: 'auto', color: this.state.vatom.properties.play_again_button_text_color, borderRadius: '50px', textAlign: 'center', fontWeight: 'bold' }}>{this.state.vatom.properties.play_again_text}</a>:null }
                {this.state.showHand ?
                    <img src={require('../../img/hand.png')} className="hand" />
                    : null}
            </div>
            :
            <div>Loading...</div>
        )
    }
}