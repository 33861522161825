import React, { Component } from 'react'
import * as typeformEmbed from '@typeform/embed'
import ScratchCard from './ScratchCard';

export default class SurveyScratch extends Component {
    constructor() {
        super()
        this.state = {
            // vatom:true,
            imageLoaded: false,
            survey_done: false,
            random: Math.random(),
            showHand: false,
            // vatom:{
            //     properties:{
            //         "play_again_button_background_color": "#8BC53F",
            // "play_again_button_text_color": "#ffffff",
            // "play_again_text": "Jugar de nuevo",
            // "redeem_action": "Redeem",
            // "redeem_button_background_color": "#D0343D",
            // "redeem_button_text_color": "#ffffff",
            // "redeem_text": "Redimir Benevit",
            // "winning_odds": "0.5"
            //     },
            //     resources:{
            //         ScratchImage:"https://cdn.blockv.io/blockv/publisher/blockv.infonavit/scratch_overlay.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NTg2MzQ3MDV9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=AzvzZuMaHNPNoLkxsQZVgCZji1f8T4inUQFE2OpcqKZ4fwJdAPQF8jqnvx2NVD5lqjD94TCvGHjz4j8-RwR1anHGgD8tJkKki27UrM7Et8BpKy022oHOsBArzKzoqfLAFQFs2XYM995yJ0U0-qWzMQqkjaUTQi2~DYDxbvnIP7lkogeihmjYrllxkAiXvou-~Bz9lIUt3mbpqcGAuAdkyhHfjig1flN1mPjveap9dllAjhJzlooNfTDm1QNzXg1enixFSN5QZ2iQIuQLqeC6GF9i8mDrRGUf4~Vi5oTwYswTYNPu48AZUu00sjCBVloAoP6jXTEP4RG8Lh8eRWRpFA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA",
            //         WinImage:"https://cdn.blockv.io/blockv/publisher/blockv.infonavit/scratch_win.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NTg2MzQ3MDV9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=AzvzZuMaHNPNoLkxsQZVgCZji1f8T4inUQFE2OpcqKZ4fwJdAPQF8jqnvx2NVD5lqjD94TCvGHjz4j8-RwR1anHGgD8tJkKki27UrM7Et8BpKy022oHOsBArzKzoqfLAFQFs2XYM995yJ0U0-qWzMQqkjaUTQi2~DYDxbvnIP7lkogeihmjYrllxkAiXvou-~Bz9lIUt3mbpqcGAuAdkyhHfjig1flN1mPjveap9dllAjhJzlooNfTDm1QNzXg1enixFSN5QZ2iQIuQLqeC6GF9i8mDrRGUf4~Vi5oTwYswTYNPu48AZUu00sjCBVloAoP6jXTEP4RG8Lh8eRWRpFA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA",
            //         LostImage:"https://cdn.blockv.io/blockv/publisher/blockv.infonavit/scratch_loose.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NTg2MzQ3MDV9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=AzvzZuMaHNPNoLkxsQZVgCZji1f8T4inUQFE2OpcqKZ4fwJdAPQF8jqnvx2NVD5lqjD94TCvGHjz4j8-RwR1anHGgD8tJkKki27UrM7Et8BpKy022oHOsBArzKzoqfLAFQFs2XYM995yJ0U0-qWzMQqkjaUTQi2~DYDxbvnIP7lkogeihmjYrllxkAiXvou-~Bz9lIUt3mbpqcGAuAdkyhHfjig1flN1mPjveap9dllAjhJzlooNfTDm1QNzXg1enixFSN5QZ2iQIuQLqeC6GF9i8mDrRGUf4~Vi5oTwYswTYNPu48AZUu00sjCBVloAoP6jXTEP4RG8Lh8eRWRpFA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA"
            //     }
            // },
            playTimes:1
        }
        this.imageLoaded = this.imageLoaded.bind(this);
    }

    imageLoaded() {
        this.setState({ showHand: true })
        setTimeout(() => {
            this.setState({ showHand: false })
        }, 1000)
    }

    componentDidMount() {
        var vatom = new window.VatomicFace.LocalVatom()
        vatom.initVatom().then(() => {
            if (!vatom.properties.owner_viewed) {
                const payload = {
                    actionName: "Viewed",
                    actionData: {
                        "this.id": vatom.identifier
                    }
                }
                window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            }
            const url = 'https://vvb.typeform.com/to/' + vatom.properties.survey_id + '?vatom_id=' + vatom.identifier
            // const url = 'https://vvb.typeform.com/to/KniGQT'
            // typeformEmbed.makeWidget(this.survey,url)
            const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this), autoStart: true })
            this.popup = popup
            this.popup.open()
            this.setState({ vatom: vatom })
            // typeformEmbed.makeWidget(this.survey, url, {onSubmit: this.handleSurveyDone.bind(this)})

        })




        // const url = 'https://vvb.typeform.com/to/KniGQT'
        //     // typeformEmbed.makeWidget(this.survey,url)
        //     const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this), autoStart: true })
        //     this.popup = popup
        //     this.popup.open()
    }


    handleSurveyDone() {
        this.popup.close()
        // this.survey.style.display="none";
        // console.log("paso");
        this.setState({ survey_done: true })
        // console.log(this.state.survey_done);
    }


    handleRedeem() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": this.state.vatom.properties.merchant_id
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.top.location = 'https://app.socioinfonavit.com/';
            }
        });
    }
    
    handePlayAgain(){
        this.setState({ done: false, random: Math.random(), playTimes:this.state.playTimes + 1}) 
        // window.location.reload();
    }

    render() {
        const settings = {
            width: window.innerWidth * .8,
            height: window.innerWidth * .8,
            image: this.state.vatom ? this.state.vatom.resources.ScratchImage : "",
            finishPercent: 60,
            imageLoaded: () => { this.imageLoaded() },
            onComplete: () => { this.setState({ done: true }) },
        };
        return (
            // this.state.vatom ?
                <div>
                    {!this.state.survey_done ?
                        null
                        :
                        <div style={{ width: '100vw', height: '100vh' }}>
                            {/* Start Header */}
                            <img src={require("../../img/spinner.svg")} style={{ height: "10vh", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: "auto", display: !this.state.imageLoaded ? "block" : "none" }} />
                            <div style={{ background: '#313131', height: "100vh", position: "relative" }}>
                                <ScratchCard key={this.state.playTimes} {...settings} ref={(scratch)=>this.scratch=scratch}>
                                    {
                                        this.state.random <= this.state.vatom.properties.winning_odds ?
                                            <img style={{ width: settings.width, height: settings.height }} src={this.state.vatom.resources.WinImage} />
                                            :
                                            <img style={{ width: settings.width, height: settings.height }} src={this.state.vatom.resources.LostImage} />
                                    }
                                </ScratchCard>

                                {this.state.done && this.state.random <= this.state.vatom.properties.winning_odds ?
                                    // Boton remidmir
                                    <a href="#" onClick={this.handleRedeem.bind(this)} style={{ position: "absolute", bottom: "10%", left: 0, right: 0, width: '90%', padding: '15px', backgroundColor: this.state.vatom.properties.redeem_button_background_color, display: 'block', margin: 'auto', color: this.state.vatom.properties.redeem_button_text_color, borderRadius: '50px', textAlign: 'center', fontWeight: 'bold' }}>{this.state.vatom.properties.redeem_text}</a> : this.state.done ?
                                        // Boton Jugar de Nuez
                                        <a onClick={this.handePlayAgain.bind(this)} style={{ position: "absolute", bottom: "10%", left: 0, right: 0, width: '80%', padding: '15px', backgroundColor: this.state.vatom.properties.play_again_button_background_color, display: 'block', margin: 'auto', color: this.state.vatom.properties.play_again_button_text_color, borderRadius: '50px', textAlign: 'center', fontWeight: 'bold' }}>{this.state.vatom.properties.play_again_text}</a> : null}
                                {this.state.showHand ?
                                    <img src={require('../../img/hand.png')} className="hand" />
                                    : null}
                            </div>
                        </div>
                    }

                </div>
                // :
                // <div>
                //     <img src={require("../../img/spinner.svg")} style={{ height: "10vh", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: "auto", display: "block" }} />
                // </div>
        )

    }
}