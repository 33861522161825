import React, { Component } from 'react';
import { Modal, Tabs, Tab, Button, FormControl } from 'react-bootstrap'
import * as typeformEmbed from '@typeform/embed'



export default class Resolutions extends Component {
  constructor() {
    super()
    this.state = {
      resolution: "",
      showModal: false,
      showModalEdit: false,
      vatom: {
        properties: {
          resolutions: [],
          completed_resolutions: []
        },

      },

    }

    this.handleNotCompleteClick = this.handleNotCompleteClick.bind(this)
  }

  componentWillMount() {
    var vatom = new window.VatomicFace.LocalVatom()
    vatom.initVatom().then(() => {
      if (!vatom.properties.owner_viewed) {
        const payload = {
          actionName: "Viewed",
          actionData: {
            "this.id": vatom.identifier
          }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
      }
      this.setState({ vatom: vatom })
    })
  }

  async handleAdd() {
    var vatom = this.state.vatom
    var resolution = this.state.resolution

    if (resolution != '') {
      if (vatom.properties.resolutions.length > 0) {
        var regex = new RegExp(vatom.properties.resolutions.join("|"), "i");
        if (!regex.test(resolution)) {
          vatom.properties.resolutions.push(resolution)
          await this.vatomicUpdate('resolutions',vatom.properties.resolutions)
        }
      }
      else{
        vatom.properties.resolutions.push(resolution)
          await this.vatomicUpdate('resolutions',vatom.properties.resolutions)
      }
      this.setState({ vatom: vatom, resolution: "", showModal: false, showModalEdit: false })
    } else {
      alert("Tienes que escribir un propósito antes de agregarlo!");
    }
  }

  updateResolutionsMedaAPI(){
    var data = {
      user_id: this.state.vatom.properties.owner,
      resolutions: this.state.vatom.properties.resolutions,
      completed_resolutions: this.state.vatom.properties.completed_resolutions
    }
    return fetch("https://api.socioinfonavit.com/update_resolutions",{
      method: 'POST',
      body:JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(res=>res.json)
    .catch(error=>console.log(error))
  }

  handleNotCompleteClick(experiencia) {
    this.setState({ experiencia: experiencia })
  }

  handleModalClose() {
    this.setState({ showModal: false })
  }
  handleModalEditClose() {
    this.setState({ showModalEdit: false })
  }

  onBack() {
    this.setState({ experiencia: null })
  }

  handleAddClick() {
    this.setState({ showModal: true })
  }

  async handleExperienceAction() {
    var vatom = this.state.vatom
    vatom.properties.completed_resolutions.push(this.state.experiencia)
    await this.vatomicUpdate('completed_resolutions', vatom.properties.completed_resolutions)
    this.setState({ experiencia: null, vatom: vatom })
  }

  async vatomicUpdate(property, value) {
    const payload = {
      actionName: "Update",
      actionData: {
        "this.id": this.state.vatom.identifier,
        properties: {
        }
      }
    }
    payload.actionData.properties[property] = value
    this.updateResolutionsMedaAPI()
    return await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
  }


  handleInputChange(e) {
    this.setState({ resolution: e.target.value })
  }

  handleEditPopUp() {
    this.setState({ showModalEdit: true, resolution: this.state.experiencia })
  }

  async handleEdit() {
    var vatom = this.state.vatom;
    vatom.properties.resolutions.splice(vatom.properties.resolutions.indexOf(this.state.experiencia), 1)
    var resolution = this.state.resolution
    var regex = new RegExp(vatom.properties.resolutions.join("|"), "i");
    if (!regex.test(resolution)) {
      vatom.properties.resolutions.push(resolution)
      await this.vatomicUpdate('resolutions', vatom.properties.resolutions)
    }

    this.setState({ vatom: vatom, resolution: "", showModal: false, showModalEdit: false, experiencia: null })
  }

  handleDeletePopUp() {
    var retVal = window.confirm("Quieres eliminar este propósito?");
    if (retVal == true) {
      this.handleDelete();
      return true;
    }
    else {

      return false;
    }
  }

  async handleDelete() {

    var vatom = this.state.vatom;
    vatom.properties.resolutions.splice(vatom.properties.resolutions.indexOf(this.state.experiencia), 1)
    await this.vatomicUpdate('resolutions', vatom.properties.resolutions)
    this.setState({ vatom: vatom, experiencia: null })
  }

  render() {
    var no_completadas = []
    var completadas = []
    if (this.state.vatom) {
      completadas = this.state.vatom.properties.completed_resolutions
      no_completadas = this.state.vatom.properties.resolutions.filter(res => !completadas.includes(res))
    }
    return (
      <div className="App">
        {!this.state.experiencia && this.state.vatom ?
          <div style={{ maxWidth: "800px", display: "block", margin: "0 auto" }}>
            <div style={{ background: "#E21936", width: "100vw", height: "30vh", backgroundImage: `url(${require("../../img/header_r.jpg")}`, backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center' }}> </div>

            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title={"Por completar (" + no_completadas.length + ")"} style={{ width: "100%", height: "63vh", overflowY: 'scroll' }} >
                {no_completadas.length > 0 ?
                  no_completadas.map((experiencia, idx) => {
                    return (
                      <div key={'no_completada' + idx} style={{ minHeight: "50px", textAlign: "left", cursor: 'pointer' }} onClick={() => { this.handleNotCompleteClick(experiencia) }}>
                        <div style={{ width: "100%", display: "inline-block", padding: "15px", position: "relative", borderBottom: "1px solid #ccc" }}>

                          <div style={{ maxWidth: "75%" }}>
                            <h4 style={{ fontFamily: "Roboto Condensed", maxWidth: "75%", height: '100%', marginBottom: '0px' }}>{experiencia}</h4>
                            <small style={{ color: "#616161" }}>Propósito # {idx + 1}</small>
                          </div>
                          <div style={{ zIndex: 99 }}>
                            <img src={require("../../img/chevron.svg")} style={{ height: "20px", position: 'absolute', right: 15, top: 0, bottom: 0, margin: 'auto', display: "block" }} />
                          </div>
                        </div>

                      </div>
                    )
                  })
                  : <h3>¡Este es tu año!<br></br>Escribe todo lo que quieres lograr aquí y estarás un paso más cerca de conseguirlo.</h3>}
                <Button style={{ position: 'fixed', bottom: 10, right: 10, background: "#E21936", color: "white", fontSize: '3.2vh', border: 'none', boxShadow: "2px 2px 10px rgba(0,0,0,0.2)" }} onClick={this.handleAddClick.bind(this)}>+ Agregar Propósito</Button>
              </Tab>
              <Tab eventKey={2} title={"Completados (" + completadas.length + ")"} style={{ width: "100%", height: "63vh", overflowY: 'scroll' }}>
                {completadas.length > 0 ?
                  completadas.map((experiencia, idx) => {

                    return (
                      <div key={'completada' + idx} style={{ minHeight: "50px", textAlign: "left", cursor: 'pointer' }}>
                        <div style={{ width: "100%", display: "inline-block", padding: "15px", position: "relative", borderBottom: "1px solid #ccc" }}>
                          <h4 style={{ fontFamily: "Roboto Condensed", maxWidth: "80%", textDecoration: 'line-through' }}>{experiencia}</h4>
                          <img src={require("../../img/check.svg")} style={{ height: "5vh", position: 'absolute', right: 15, top: 0, bottom: 0, margin: "auto", display: "block" }} />
                        </div>

                      </div>
                    )
                  })
                  : <h3>No tienes nada completado</h3>}
              </Tab>
            </Tabs>
          </div>
          :
          this.state.vatom ?
            <div style={{ maxWidth: "800px", display: "block", margin: "0 auto" }}>
              {this.state.experiencia.checked ?
                <div style={{ height: "100vh" }}>
                  <img onClick={this.onBack.bind(this)} src={require("../../img/chevron_w.png")} style={{ position: 'absolute', top: "15px", left: "15px", width: "30px", transform: "rotate(180deg)", cursor: "pointer" }} />
                  <div style={{ background: "#E21936", backgroundImage: `linear-gradient(to right bottom, rgb(226, 25, 54, 0.6), rgba(0, 0, 0, 0.6)), url(${require("../../img/header_r.jpg")})`, height: "40vh", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center top' }}>
                    <h1 style={{ fontFamily: "Roboto Condensed", textAlign: "center", color: "white", margin: "0", paddingTop: "17vh" }}>¡COMPLETADO!</h1>
                  </div>
                  <div style={{ width: "85%", display: "block", margin: "0 auto" }}>
                    <p style={{ marginTop: "15%", color: "#616161" }}>Completaste este propósito:</p>
                    <h4 style={{ fontFamily: "Roboto Condensed", textAlign: "center" }}>{this.state.experiencia}</h4>
                    <br></br>
                    {/* <a onClick={this.handleExperienceAction.bind(this)} style={{ padding: "15px", borderRadius: "50px", background: "#bc2e47", width: "50%", textAlign: "center", color: "white", fontWeight: "bolder"}}>Ya completé este propósito</a> */}
                    <div style={{ marginTop: "50px" }}>
                      <div style={{ display: 'inline-block', width: "49%", textAlign: 'center' }}>
                        <a onClick={this.handleEditPopUp.bind(this)}><img style={{ width: 20 }} src={require('../../img/edit.png')} />&nbsp;Editarlo</a>
                      </div>
                      <div style={{ display: 'inline-block', width: "49%", textAlign: 'center', color: '#000!important' }}>
                        <a onClick={this.handleDeletePopUp.bind(this)}><img style={{ width: 20 }} src={require('../../img/delete.png')} />&nbsp;Eliminarlo</a>
                      </div>
                    </div>

                  </div>
                </div> :
                <div style={{ height: "100vh" }}>
                  <img onClick={this.onBack.bind(this)} src={require("../../img/chevron_w.png")} style={{ position: 'absolute', top: "15px", left: "15px", width: "30px", transform: "rotate(180deg)", cursor: "pointer" }} />
                  <div style={{ background: "#E21936", backgroundImage: `url(${require("../../img/header_r.jpg")})`, height: "40vh", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center top' }}>
                  </div>
                  <div style={{ width: "85%", display: "block", margin: "0 auto" }}>
                    <p style={{ marginTop: "15%", color: "#616161" }}>Tu propósito para el 2019 es:</p>
                    <h4 style={{ fontFamily: "Roboto Condensed", textAlign: "center" }}>{this.state.experiencia}</h4>
                    <br></br>
                    <a onClick={this.handleExperienceAction.bind(this)} style={{ padding: "15px", borderRadius: "50px", background: "#E21936", width: "50%", textAlign: "center", color: "white", fontWeight: "bolder", cursor: "pointer" }}>Sí completé este propósito</a>
                    <div style={{ marginTop: "50px" }}>
                      <div style={{ display: 'inline-block', width: "49%", textAlign: 'center' }}>
                        <a onClick={this.handleEditPopUp.bind(this)}><img style={{ width: 20 }} src={require('../../img/edit.png')} />&nbsp;Editarlo</a>
                      </div>
                      <div style={{ display: 'inline-block', width: "49%", textAlign: 'center', color: '#000!important' }}>
                        <a onClick={this.handleDeletePopUp.bind(this)}><img style={{ width: 20 }} src={require('../../img/delete.png')} />&nbsp;Eliminarlo</a>
                      </div>
                    </div>

                  </div>
                </div>
              }
            </div>
            : null
        }
        <Modal show={this.state.showModal} onHide={this.handleModalClose.bind(this)}>
          <Modal.Body>
            <h2 style={{ textAlign: "center", color:"#E21936" }}>Propósitos<br></br>2019</h2>
            {/* <img src={require("../../img/tnx_r.png")} style={{ width: "100%", margin: "0 auto", display: "block", padding: "30px 0" }} /> */}
            <h4 style={{ textAlign: "center" }}>Agrega un propósito de año nuevo</h4>
            <FormControl autoFocus value={this.state.resolution} onChange={this.handleInputChange.bind(this)} type="text" placeholder="Escribe aquí tu propósito" />
            <div style={{ textAlign: "right", marginTop: "5%" }}>
              <Button bsStyle="primary" bsSize="large" onClick={this.handleModalClose.bind(this)} style={{ display: "inline-block", background: "#ccc", marginRight: "2%", color: "#000", border: "none" }}>
                Cancelar
              </Button>
              <Button bsStyle="primary" bsSize="large" className={"pullRight"} onClick={this.handleAdd.bind(this)} style={{ display: "inline-block", background: "#E21936" }}>
                Agregar
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showModalEdit} onHide={this.handleModalEditClose.bind(this)}>
          <Modal.Body>
            <img src={require("../../img/tnx_r.png")} style={{ width: "100%", margin: "0 auto", display: "block", padding: "30px 0" }} />
            <h4 style={{ textAlign: "center" }}>Agrega un propósito de año nuevo</h4>
            <FormControl autoFocus value={this.state.resolution} onChange={this.handleInputChange.bind(this)} type="text" placeholder="Escribe aquí tu propósito" />
            <div style={{ textAlign: "right", marginTop: "5%" }}>
              <Button bsStyle="primary" bsSize="large" onClick={this.handleModalClose.bind(this)} style={{ display: "inline-block", background: "#ccc", marginRight: "2%", color: "#000", border: "none" }}>
                Cancelar
              </Button>
              <Button bsStyle="primary" bsSize="large" className={"pullRight"} onClick={this.handleEdit.bind(this)} style={{ display: "inline-block", background: "#E21936" }}>
                Guardar
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
