import React, { Component } from 'react';
import Spinner from './Spinner'
function RepeatButton(props) {
    return (
        <button
            aria-label='Jugar de nuevo'
            id='repeatButton'
            onClick={props.onClick}
            >Jugar de nuevo
        </button>
    );
}

function WinningSound() {
    return (
        <audio autoplay="autoPlay" className="player" preload="false">
            <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" />
        </audio>
    );
}


export default class Slot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            winner: null
        }
        this.finishHandler = this.finishHandler.bind(this)
        this.handleClick = this.handleClick.bind(this);
        this.matches = [];
        this.loser = [
            'Ups, perdiste jaja',
            'Ya deja de andar perdiendo',
            'Hey, perdiste!',
            'Ouch! Eso dolió',
            'No seas tan duro contigo',
            'Ups, ahí va tu fondo de ahorro',
            'Nada por aquí, nada por allá',
            '¡Eres buenísimo, perdiendo!',
            
        ];
    }

        handleClick() {
            this.setState({ winner: null });
            this.emptyArray();
            this._child1.forceUpdateHandler();      
            this._child2.forceUpdateHandler();
            this._child3.forceUpdateHandler();
        }

    

     

    finishHandler(value) {
        this.matches.push(value);
        if (this.matches.length === 3) {
            const { winner } = this.state;
            const first = this.matches[0];
            let results = this.matches.every(match => match === first)
            this.setState({ winner: results });
        }
    }

    emptyArray() {
        this.matches = [];
    }

    render() {
        const { winner } = this.state;
        const getLoser = () => {
            return this.loser[Math.floor(Math.random() * this.loser.length)]
        }
        let repeatButton = null;
        let winningSound = null;

        if (winner !== null) {
            repeatButton = <RepeatButton onClick={this.handleClick} />
        }

        if (winner) {
            winningSound = <WinningSound />
        }

        return (
            <div style={{ backgroundColor: '#313131', height:'100vh', fontFamily:'Roboto Condensed'}}>
            <div>
                {winningSound}
               
                    <p style={{color:'white', textAlign:'center', paddingTop:'15vh', fontSize:'4vh'}}>{winner === null ? 'Espera...' : winner ? '¡GANASTE! BENEVIT' : getLoser()}</p>
                    {!winner &&  winner != null ?
                    <p style={{color:"white", textAlign:'center', fontSize:'100%'}}>No desesperes, vuelve a intentarlo</p>:
                    null
                    }

                <div className={`spinner-container`}>
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child1 = child; }} timer="1000" />
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child2 = child; }} timer="1400" />
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child3 = child; }} timer="2200" />
                    <div className="gradient-fade"></div>
                </div>
                <div style={{position:"absolute", bottom:'10%', width:'100%'}}>
                        {repeatButton}
                </div>
                
            </div>
                
            </div>
            
        );
    }
}  



   
