import React, { Component } from 'react'
import * as typeformEmbed from '@typeform/embed'

export default class SurveyContentImage extends Component {
    constructor() {
        super()
        this.state = {
            // vatom:true,
            imageLoaded: false,
            survey_done: false,
            surveyImage: 'url(http://placehold.it/1200x600)',
            surveyText: "El Infonavit construye casas (Falso)<br>El Infonavit no construye las casas.Tiene dos funciones principales:<br></br>1) Administrar el dinero que recibe de los trabajadores como prestación social de vivienda y que equivale al 5% de su salario base de cotización.<br><br>2) Otorgar créditos a los trabajadores que quieran comprar, construir, cambiar, ampliar o remodelar una vivienda, o bien pagar un crédito hipotecario de otra institución financiera."
        }
    }

    componentDidMount() {
        var vatom = new window.VatomicFace.LocalVatom()
        vatom.initVatom().then(() => {
            if (!vatom.properties.owner_viewed) {
                const payload = {
                    actionName: "Viewed",
                    actionData: {
                        "this.id": vatom.identifier
                    }
                }
                window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            }
            const url = 'https://vvb.typeform.com/to/' + vatom.properties.survey_id + '?vatom_id=' + vatom.identifier
            // const url = 'https://vvb.typeform.com/to/KniGQT'
            // typeformEmbed.makeWidget(this.survey,url)
            const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this), autoStart: true })
            this.popup = popup
            this.popup.open()
            this.setState({ vatom: vatom })
            // typeformEmbed.makeWidget(this.survey, url, {onSubmit: this.handleSurveyDone.bind(this)})

        })
    }


    handleSurveyDone() {
        this.popup.close()
        // this.survey.style.display="none";
        // console.log("paso");
        this.setState({ survey_done: true })
        // console.log(this.state.survey_done);
    }


    handleRedeem() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": this.state.vatom.properties.merchant_id
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.top.location = 'https://app.socioinfonavit.com/';
            }
        });
    }

    render() {
        return (
            this.state.vatom ?
                <div>
                    {!this.state.survey_done ?
                        null
                        :
                        <div style={{ width: '100vw', height: '100vh' }}>
                            {/* Start Header */}
                            <img src={require("../../img/spinner.svg")} style={{ height: "10vh", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: "auto", display: !this.state.imageLoaded ? "block" : "none"}} />
                            <img onLoad={()=>this.setState({imageLoaded:true})} src={this.state.vatom.resources.CardImage} style={{ width: "100%", height: "auto", overflowY: "scroll" }} />
                            {/* End Header */}
                            {/* Start Body Container */}
                            <div style={{ height: "90px", position: "fixed", bottom: "0", width: "100%", backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0.7),rgba(255,255,255,1))" }}>
                                <a onClick={this.handleRedeem.bind(this)} style={{ cursor:"pointer",
                                    width: "80%", height: "55px", display: "block", margin: "15px auto", color: this.state.vatom.properties.redeem_button_text_color, backgroundColor: this.state.vatom.properties.redeem_button_background_color, borderRadius: "50px",
                                    textAlign: "center", lineHeight: "55px", fontWeight: 700
                                }}>{this.state.vatom.properties.redeem_text}</a>
                            </div>
                            {/* End Button Container */}
                        </div>
                    }

                </div>
                :
                <div>
                    <img src={require("../../img/spinner.svg")} style={{ height: "10vh", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: "auto", display: "block" }} />
                </div>
        )

    }
}