import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//Components
import Experiences from './Components/Experiences/Experiences'
import Resolutions from './Components/Resolutions/Resolutions';
import Scratch from './Components/Faces/Scratch';
import Slot from './Components/Faces/Slot';
import SurveyContentImage from './Components/Faces/SurveyContentImage'
import SurveyScratch from './Components/Faces/SurveyScratch'

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/slot" component={Slot} />
          <Route path="/experiences" component={Experiences} />
          <Route path="/resolutions" component={Resolutions} />
          <Route path="/scratch" component={Scratch} />
          <Route path="/survey_image" component={SurveyContentImage} />
          <Route path="/survey_scratch" component={SurveyScratch} />
        </Switch>
      </Router>
    );
  }
}

export default App;
